// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';
import App from './App'; // Tu componente principal de la aplicación
import Install from './Install'; // Tu componente para la instalación de la PWA
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/install" component={Install} />
        {/* Puedes agregar más rutas aquí si es necesario */}
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);



// serviceWorkerRegistration.register();