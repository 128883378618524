import React, { useRef, useState, useEffect } from "react";
import * as tf from "@tensorflow/tfjs";
import * as cocoSsd from "@tensorflow-models/coco-ssd"; // Importa cocoSsd
import Webcam from "react-webcam";
import "./App.css";
// import utils
// import { drawRect } from "./utils";

// const API_URL = 'http://localhost:1437/api/vehicles';
const API_URL = 'https://apisafe.softwerty.com/api/vehicles';

const API_TOKEN = '65aad0c62d25cdb97a8f8835e7f0c1a732d70c8e0de22b94992ef3eece58b4d5b0ada6ecf1d6b8747b669d59f31a39de1d6d9b1d3d41eecc6cc619a8b41a71c2efa8a6d23e828c1685bccd3181b7318951aa35f676728a8aeb6d4d0cc956c55a8b106f697898ec4c85cefa5da1d6bfc4e95993eabc05ae457aeb9930c0a25cdb'

export const drawRect = (predictions, ctx, videoWidth, videoHeight) => {
  predictions.forEach(prediction => {
    // si es car y si el score es mayor a 0.5
    if (prediction['class'] === 'car' && prediction['score'] > 0.7) {
      // Asumir que prediction['bbox'] contiene [x, y, width, height]
      const [x, y, w, h] = prediction['bbox'];

      // Asegúrate de que el color está definido, aunque sea un color por defecto
      const color = 'red';

      // Dibujar el rectángulo
      ctx.strokeStyle = color;
      ctx.lineWidth = 3;
      ctx.strokeRect(x, y, w, h);

      // Dibujar el texto
      ctx.fillStyle = color;
      ctx.fillText(`Clase ${prediction['class']}`, x, y);
    }


  });
};

function App() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [cocoNet, setCocoNet] = useState(null);

  // Carga ambos modelos al iniciar la aplicación
  useEffect(() => {
    let isCancelled = false;

    async function loadModels() {
      const cocoModel = await cocoSsd.load();

      if (!isCancelled) {
        setCocoNet(cocoModel);
        console.log('Models loaded');
      }
    }

    loadModels();

    // Cleanup function in case the component is unmounted before the models are loaded
    return () => {
      isCancelled = true;
    };
  }, []);






  // Función para detectar objetos y buscar patentes
  const detect = async () => {
    if (webcamRef.current && webcamRef.current.video.readyState === 4 && cocoNet) {
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Ajusta las dimensiones del canvas visible
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      const ctx = canvasRef.current.getContext("2d");

      // Captura el frame actual del video
      ctx.drawImage(video, 0, 0, videoWidth, videoHeight);

      const img = tf.browser.fromPixels(canvasRef.current);
      const cocoPredictions = await cocoNet.detect(img);
      tf.dispose(img);

      // Ahora `drawRect` modificará directamente el canvas visible con las predicciones
      drawRect(cocoPredictions, ctx, videoWidth, videoHeight);
      detectAndSendVehicles(cocoPredictions, webcamRef);
    }
  };





  const detectionMemory = [];

  const generateUniqueId = (prediction) => {
    // Implementación de ejemplo: concatena y redondea las coordenadas del bbox
    return `${Math.round(prediction.bbox[0])},${Math.round(prediction.bbox[1])},${Math.round(prediction.bbox[2])},${Math.round(prediction.bbox[3])}`;
  };

  const isSimilarDetection = (det1, det2) => {
    const threshold = 1150; // Define un umbral de "similitud" en píxeles
    const distance = Math.sqrt(Math.pow(det1.bbox[0] - det2.bbox[0], 2) + Math.pow(det1.bbox[1] - det2.bbox[1], 2));
    return distance < threshold;
  };

  const updateDetectionMemory = (prediction, currentTime) => {
    const existingIndex = detectionMemory.findIndex(det => det.id === prediction.id);
    if (existingIndex !== -1) {
      // Actualiza el tiempo de la última detección si la detección actual es similar a la existente
      if (isSimilarDetection(detectionMemory[existingIndex], prediction)) {
        detectionMemory[existingIndex].lastSent = currentTime;
      }
    } else {
      // Añade una nueva detección
      detectionMemory.push({ ...prediction, lastSent: currentTime });
    }
  };

  const detectAndSendVehicles = async (predictions, webcamRef) => {
    const currentTime = Date.now();
    predictions.forEach(async (prediction) => {
      if (prediction.class === 'car' && prediction.score > 0.65) {
        const predictionId = generateUniqueId(prediction); // Considera mejorar esta función
        let existingDetection = detectionMemory.find(det => isSimilarDetection(det, prediction));

        if (existingDetection) {
          // Verifica si ha habido un movimiento significativo o si ha pasado suficiente tiempo
          if (currentTime - existingDetection.lastSent > calculateDynamicInterval(existingDetection, prediction)) {
            await extractAndSendVehicleImage(prediction, webcamRef);
            existingDetection.lastSent = currentTime;
            existingDetection.bbox = prediction.bbox; // Actualiza la posición
          }
        } else {
          // Nuevo vehículo detectado
          await extractAndSendVehicleImage(prediction, webcamRef);
          detectionMemory.push({ id: predictionId, bbox: prediction.bbox, lastSent: currentTime });
        }
      }
    });
  };

  const calculateDynamicInterval = (existingDetection, newDetection) => {
    // Implementa lógica para ajustar el intervalo basado en el movimiento y otros factores
    return 2000; // Retorna un intervalo en milisegundos
  };

  const sendVehicleData = async (formData) => {
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${API_TOKEN}`,
        },
        body: formData,
      });
      const data = await response.json();
      console.log('Respuesta del servidor:', data);
    } catch (error) {
      console.error('Error al enviar la imagen:', error);
    }
  };


  const extractAndSendVehicleImage = async (prediction, webcamRef) => {

    const video = webcamRef.current.video;
    const [x, y, width, height] = prediction.bbox;
    const scale = video.videoWidth / video.offsetWidth; // Ajusta según el escalado del video al canvas

    // Crear un canvas temporal para capturar la imagen del vehículo
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    // Dibujar el segmento del vehículo en el canvas
    ctx.drawImage(
      video,
      x * scale, y * scale, width * scale, height * scale, // Origen en el video
      0, 0, width, height // Destino en el canvas
    );

    // Convertir el canvas a blob
    canvas.toBlob(async (blob) => {

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        // console.log('base64data:', base64data);
        // print image in element "preview"
        document.getElementById('preview').src = base64data;
      }


      const formData = new FormData();
      formData.append('files.photo', blob, 'vehicle.jpg');
      formData.append("data", JSON.stringify({
        "user_token": "usrtokenxn",
        "mapa_lat": "123",
        "mapa_long": "-66"
      }));

      await sendVehicleData(formData);

    }, 'image/jpeg');


  };








  const goFullScreen = () => {
    const videoElement = webcamRef.current.video;

    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) { /* Firefox */
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) { /* IE/Edge */
      videoElement.msRequestFullscreen();
    }
  };


  const [devices, setDevices] = useState([]);
  const [currentDeviceId, setCurrentDeviceId] = useState("");
  const switchCamera = () => {
    const currentIndex = devices.findIndex(device => device.deviceId === currentDeviceId);
    const nextIndex = (currentIndex + 1) % devices.length; // Esto permite un bucle circular a través de las cámaras
    setCurrentDeviceId(devices[nextIndex]?.deviceId);
  };

  useEffect(() => {
    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      setDevices(videoDevices);
      setCurrentDeviceId(videoDevices[0]?.deviceId);
    };

    getDevices();
  }, []);

  useEffect(() => {
    const videoElement = webcamRef.current.video;

    const handleFullScreenChange = () => {
      if (document.fullscreenElement === videoElement) {
        canvasRef.current.width = window.screen.width;
        canvasRef.current.height = window.screen.height;
      } else {
        canvasRef.current.width = videoElement.offsetWidth;
        canvasRef.current.height = videoElement.offsetHeight;
      }
      // Aquí puedes agregar lógica adicional si es necesario para redibujar los bounding boxes
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  // Iniciar la detección
  useEffect(() => {
    if (cocoNet) {
      const interval = setInterval(() => {
        detect(); // Se llama sin parámetros ya que cocoNet está en el estado
      }, 100);
      return () => clearInterval(interval);
    }
  }, [cocoNet]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="camera">
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={{ width: 1920, height: 1080, deviceId: currentDeviceId }}
          />
          <div className="camera_overlay"></div>
          <canvas className="camera_canvas" ref={canvasRef} />
          <button onClick={goFullScreen} className="fullscreenButton">Pantalla Completa</button>
          <button onClick={switchCamera} className="switchCameraButton">Cambiar Cámara</button>
        </div>
        <img id="preview" src="" alt="preview" />
      </header>
      {/* Resto de tu componente */}
    </div>
  );
}

export default App;
