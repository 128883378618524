import React, { useEffect, useState } from 'react';
import './Install.css';

const Install = () => {
  const [promptInstall, setPromptInstall] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [installMessage, setInstallMessage] = useState('');

  useEffect(() => {
    const handler = (e) => {
      // Prevenir el evento por defecto permite que puedas mostrar el prompt manualmente
      e.preventDefault();
      console.log("Evento 'beforeinstallprompt' capturado.");
      setPromptInstall(e);
      setIsInstallable(true); // La app es instalable
    };
    
    window.addEventListener('beforeinstallprompt', handler);

    // Verificar si la app ya está instalada o no
    window.addEventListener('appinstalled', (event) => {
      console.log('La aplicación ha sido instalada.');
      // Actualizar el estado para reflejar que la app ha sido instalada
      setIsInstallable(false);
      setInstallMessage('La aplicación ya ha sido instalada.');
    });

    // Comprobar si la plataforma es capaz de instalar la PWA
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log('La aplicación ya está corriendo en modo standalone.');
      setIsInstallable(false);
      setInstallMessage('La aplicación ya está corriendo en modo standalone.');
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = (e) => {
    if (!promptInstall) return;

    promptInstall.prompt();
    promptInstall.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('El usuario aceptó la instalación de la A2HS.');
        setInstallMessage('¡Gracias por instalar nuestra app!');
      } else {
        console.log('El usuario rechazó la instalación de la A2HS.');
        setInstallMessage('Instalación rechazada. Puedes instalar la app más tarde si cambias de opinión.');
      }
      setPromptInstall(null);
      setIsInstallable(false);
    });
  };

  return (
    <div className="install-container">
      {isInstallable ? (
        <button className="install-button" onClick={handleInstallClick}>
          Instalar App
        </button>
      ) : (
        <p>{installMessage || 'La aplicación no está disponible para ser instalada en este dispositivo.'}</p>
      )}
    </div>
  );
};

export default Install;
